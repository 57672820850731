import * as React from 'react';
import * as background from '../assets/Insights.png';
import * as icon from '../assets/logo_large.png';

import { ArticleNode, Edge, MyTheme } from 'src/types/types';
import { Hidden, Visible } from 'react-grid-system';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import ArticleBar from '../components/ArticleBar';
import ArticleGrid from '../components/ArticleGrid';
import ArticleSummary from '../components/ArticleGrid/ArticleSummary';
import { Footer } from '../components/Footer';
import HighlightedText from '../components/Text/Highlighted';
import PageHeader from '../components/PageHeader';
import Spacer from '../components/Text/Spacer';
import Subscribe from '../components/Subscribe/SubscribeBanner';
import { graphql } from 'gatsby';

const blogPageHeader = {
    title: 'News & insights',
    subtitle: 'In-house energy news and market-focused insights powered by Vortexa',
    icon,
    background
};

type OwnProps = {
    data: {
        articles: Edge<ArticleNode>;
        highlightedArticle: Edge<ArticleNode>;
    };
};

const BlogPage = ({ classes, data }: WithSheet<StyleCreator<string, any>> & OwnProps) => {
    const { articles, highlightedArticle } = data;
    return (
        <div>
            <PageHeader data={blogPageHeader} />
            <div className={classes.main}>
                <ArticleBar />
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Spacer />
                        <HighlightedText highlightedText={'Featured'} />
                        <Spacer />
                        <Visible xs>
                            <ArticleSummary article={highlightedArticle.edges[0]} />
                        </Visible>
                        <Hidden xs>
                            <ArticleSummary article={highlightedArticle.edges[0]} horizontal />
                        </Hidden>
                        <Spacer />
                        <h1 className={classes.articlesTitle}>Insights</h1>
                        <Spacer />
                        <ArticleGrid articles={articles} />
                    </div>
                </div>
                <Spacer />
                <Subscribe />
                <Spacer />
                <Footer />
            </div>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    main: {
        marginTop: '70vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: theme.palette.basic.white
    },
    container: theme.content,
    content: {
        margin: `0px ${theme.basePadding}px`
    },
    articlesTitle: {
        textAlign: 'center'
    }
});

export default injectSheet(styles)(BlogPage);

export const query = graphql`
    query {
        articles: allButterPost(skip: 1, sort: { fields: published, order: DESC }) {
            ...Articles
        }
        highlightedArticle: allButterPost(limit: 1, sort: { fields: published, order: DESC }) {
            ...Articles
        }
    }
`;
