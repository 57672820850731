import * as React from 'react';
import * as cn from 'classnames';
import * as moment from 'moment/moment';

import { ArticleNode, MyTheme } from 'src/types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss';

import Button from '../Button';
import { Link } from 'gatsby';
import { resizeImage } from '../../utils/images';

type OwnProps = {
    article: ArticleNode;
    horizontal?: true;
};

const ArticleSummary = ({ classes, article, horizontal }: WithSheet<StyleCreator<string, any>> & OwnProps) => {
    const { slug, summary, featured_image, published, title } = article.node;

    return (
        <div key={slug} className={cn(classes.entry, horizontal && classes.horizontalEntry)}>
            <div className={cn(classes.imageWrapper, horizontal && classes.horizontalImageWrapper)}>
                <Link to={`/insight/${slug}`}>
                    <div
                        className={classes.image}
                        style={{
                            backgroundImage: `url(${resizeImage(featured_image ? featured_image : '', 80, 470)})`
                        }}
                    />
                </Link>
            </div>

            <div className={classes.text}>
                <div>{moment(published).format('MMM D, YYYY')}</div>
                <Link to={`/insight/${slug}`}>
                    <h3 className={classes.title}>{title}</h3>
                </Link>
                <div>{`${summary.substring(0, 105)}${summary.length > 105 ? '...' : ''}`}</div>
                {horizontal && (
                    <div className={classes.action}>
                        <Link to={`/insight/${slug}`}>
                            <Button>Read this article</Button>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    entry: {
        background: theme.palette.fog.lightContainer,
        '&:hover': {
            background: theme.palette.fog.primary
        },
        transition: '.8s',
        height: '550px'
    },
    horizontalEntry: {
        display: 'flex',
        height: 'auto'
    },
    imageWrapper: {
        overflow: 'hidden',
        height: '300px'
    },
    horizontalImageWrapper: {
        width: '50%',
        height: 'auto'
    },
    title: {
        cursor: 'pointer',
        fontSize: theme.typography.sizes.h2,
        height: '70px',
        overflow: 'hidden'
    },
    image: {
        cursor: 'pointer',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%'
    },
    '@media(min-width: 1200px)': {
        image: {
            filter: `grayscale(100%)`,
            transition: '.3s ease-in-out',
            '&:hover': {
                filter: `grayscale(10%)`,
                transform: 'scale(1.1)'
            }
        }
    },
    author: {
        color: theme.palette.vortexa.active
    },
    text: {
        padding: theme.cardContentPadding
    },
    action: {
        marginTop: theme.basePadding
    }
});

export default injectSheet(styles)(ArticleSummary);
