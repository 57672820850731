import * as React from 'react';

import { ArticleNode, Edge } from 'src/types/types';
import { Col, Row } from 'react-grid-system';

import ArticleSummary from './ArticleSummary';
import Button from '../Button';
import Spacer from '../Text/Spacer';

const DEFAULT_VISIBLE_ARTICLES = 9;
const DEFAULT_VISIBLE_INCREMENT = 3;

type OwnProps = {
    articles: Edge<ArticleNode>;
};

const ArticleGrid = ({ articles }: OwnProps) => {
    const [visibleCount, setVisibleCount] = React.useState<number>(DEFAULT_VISIBLE_ARTICLES);

    return (
        <Row>
            {articles.edges.map((article, i) => {
                if (i >= visibleCount) {
                    return;
                }

                return (
                    <Col sm={12} lg={6} xl={4} key={`article-${i}`}>
                        <ArticleSummary article={article} />
                        <Spacer />
                    </Col>
                );
            })}
            {visibleCount < articles.edges.length && (
                <Button
                    onClick={() => setVisibleCount(visibleCount + DEFAULT_VISIBLE_INCREMENT)}
                    style={{ margin: '0px auto' }}
                >
                    Show more
                </Button>
            )}
        </Row>
    );
};

export default ArticleGrid;
